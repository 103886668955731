module.exports = {
	"email": "Epost",
	"phone": "Telefon",
	"name": "Navn",
	"avatar": "Profilbilde",
	"save": "Lagre",
	"saved": "Lagre",
	"saving": "Lagrer",
	"edit": "Rediger",
	"create": "Opprett",
	"remove": "Slett",
	"reinvite": "Inviter på nytt",
	"createUser": "Opprett bruker",
	"loading": "Laster {resource}",
	"dashboard": "Dashbord",
	"project": "Prosjekt | Prosjekter",
	"theProject": "Prosjektet",
	"map": "Kart",
	"yourProject": "Ditt prosjekt | Dine prosjekter",
	"allProjects": "Alle prosjekter",
	"noProjects": "Ingen prosjekter",
	"residential": "Bolig | Boliger",
	"noResidentials": "Ingen boliger",
	"residentialTemplate": "Boligtype | Boligtyper",
	"createResidentialTemplate": "Opprett boligtype",
	"createUnitTemplate": "Opprett enhetstype",
	"user": "Bruker | Brukere",
	"settings": "Innstillinger",
	"login": "Logg inn",
	"logout": "Logg ut",
	"termsCondition": "Avtalevilkår",
	"privacyPolicy": "Personvern",
	"cookiePolicy": "Cookie Policy",
	"menu": "Meny",
	"followUs": "Følg oss",
	"getStarted": "Kom i gang",
	"noAccess": "Ingen tilgang",
	"projectName": "Prosjektnavn",
	"publish": "Publiser",
	"publishing": "Publisering",
	"published": "Publisert",
	"unpublished": "Ikke publisert",
	"domain": "Domene",
	"retry": "Prøv igjen",
	"close": "Lukk",
	"showDetails": "Vis detaljer",
	"hideDetails": "Skjul detaljer",
	"createLead": "Ny interessent",
	"newLead": "Ny interessent",
	"lead": "Interessent | Interessenter",
	"leadMessage": "Beskjed",
	"message": "Melding | Meldinger",
	"newMessage": "Ny melding",
	"comment": "@:message",
	"noMessages": "Ingen meldinger",
	"description": "Beskrivelse",
	"showMore": "Vis fler",
	"noLeads": "Ingen interessenter",
	"openhouse": "Visning | Visninger",
	"openhouseOnRequest": "Visning etter avtale",
	"noOpenhouse": "Ingen visninger",
	"createOpenhouse": "Ny visning",
	"from": "Fra",
	"to": "Til",
	"date": "Dato",
	"role": "Rolle | Roller",
	"flatfinder": "Boligvelger | Boligvelgere",
	"unitSelector": "Enhetsvelger | Enhetsvelgere",
	"flatfinderTypeFlat": "Leilighetsvelger | Leilighetsvelgere",
	"flatfinderTypePlot": "Tomtevelger | Tomtevelgere",
	"flatfinderTypeOffice": "Kontor | Kontorer",
	"createFlatfinder": "Ny boligvelger",
	"createUnitSelector": "Ny enhetsvelger",
	"home": "Hjem",
	"statistics": "Statistikk",
	"notset": "Ikke satt",
	"projectmanager": "Prosjektmedarbeider | Prosjektmedarbeidere",
	"customermanager": "Kundebehandler | Kundebehandlere",
	"complaintmanager": "Reklamasjonsansvarlig | Reklamasjonsansvarlige",
	"search": "Søk",
	"searchFor": "Søk etter",
	"cancel": "Avbryt",
	"confirm": "Bekreft",
	"couldnotfindonquery": "Ingen treff for \"{query}\"",
	"selectx": "Velg {x}",
	"brochure": "Prospekt",
	"downloadBrochure": "Last ned prospekt",
	"logo": "Logo",
	"logoInverted": "Logo (lys variant)",
	"favicon": "Favicon (Nettleserikon)",
	"gallery": "Galleri",
	"internalId": "Intern ID",
	"createProject": "Nytt prosjekt",
	"address": "Adresse",
	"postcode": "Postnummer",
	"city": "Sted",
	"county": "Fylke",
	"latitude": "Breddegrad",
	"longitude": "Lengdegrad",
	"position": "Posisjon",
	"createResidential": "Ny bolig",
	"cadastre": "Matrikkel",
	"residentialnumber": "Bolignummer",
	"number": "Nummer",
	"street": "Gateadresse",
	"floorplan": "Plantegning | Plantegninger",
	"dragImageOrDrop": "Dra bildet hit eller <b>trykk</b> for å laste opp | Dra bilder hit eller <b>trykk</b> for å laste opp",
	"dragFilesOrDrop": "Dra filer {extension} hit eller <b>trykk</b> for å laste opp",
	"dragFileOrDrop": "Dra fil {extension} hit eller <b>trykk</b> for å laste opp",
	"price": "Pris",
	"residentialProperties": "Nøkkelinfo",
	"propertyType": "Enhetstype",
	"propertyTypeWarning": "<b>OBS!</b> Du har valgt boligtype \"{value}\" og må derfor legge inn verdier per enhet.",
	"numberOfBedrooms": "Soverom",
	"numberOfRooms": "Rom",
	"numberOfBathrooms": "Bad/WC",
	"primaryRoomArea": "Primærrom",
	"grossArea": "Bruttoareal",
	"useableArea": "Bruksareal",
	"floor": "Etasje",
	"numberOfFloors": "Antall etasjer",
	"plotArea": "Tomteareal",
	"ownershipType": "Eieform",
	"numberOfParkingSpots": "Antall parkeringsplasser",
	"energyLabel": "Energimerking",
	"plotOwned": "Eiet tomt",
	"plotGroundTax": "Eiendomsskatt",
	"plotOwnershipType": "Eierforhold (tomt)",
	"communityTax": "Kommunale avgifter",
	"taxValue": "Formuesverdi",
	"constructionYear": "Byggeår",
	"renovatedYear": "Renovert år",
	"value": "Verdi",
	"add": "Legg til",
	"custom": "Egendefinert",
	"yes": "Ja",
	"no": "Nei",
	"quantity": "Antall",
	"none": "Ingen",
	"bid": "Kjøp",
	"addBid": "Nytt kjøp",
	"bidUrl": "Kjøpslenke",
	"purchaseUrl": "Lenke for signering av kjøpekontrakt",
	"size": "Størrelse",
	"sale": "Salg",
	"basis": "Grunnlag",
	"presentation": "Presentasjon",
	"integration": "Integrasjon | Integrasjoner",
	"next": "Neste",
	"prev": "Forrige",
	"cover": "Cover",
	"propertyType:detached": "Enebolig | Eneboliger",
	"propertyType:flat": "Leilighet | Leiligheter",
	"propertyType:terraced": "Rekkehus",
	"propertyType:semidetached": "Tomannsbolig",
	"propertyType:plot": "Boligtomt | Boligtomter",
	"propertyType:leisure": "Hytte | Hytter",
	"propertyType:leisurePlot": "Fritidstomt | Fritidstomter",
	"propertyType:office": "Kontor | Kontorer",
	"openhouseReferences": "Hvor er det visning",
	"isInterestedIn": "Er interessert i",
	"leadReferences": "@:isInterestedIn",
	"theWholeProject": "Hele prosjektet",
	"getInTouch": "Kontakt oss",
	"leadFormTitle": "Meld interesse",
	"leadConfirm": "Takk for din interesse",
	"residentialStatus": {
		"draft": "@:unpublished",
		"upcoming": "Kommer for salg",
		"sale": "Til salgs",
		"sold": "Solgt",
		"reserved": "Reservert"
	},
	"residentialStatus:sale": {
		"draft": "@:unpublished",
		"upcoming": "Kommer for salg",
		"sale": "Til salgs",
		"sold": "Solgt",
		"reserved": "Reservert"
	},
	"residentialStatus:rent": {
		"draft": "@:unpublished",
		"upcoming": "Kommer til leie",
		"reserved": "Reservert",
		"rent": "Til leie",
		"rented": "Utleid"
	},
	"viewResidentials": "Se boliger",
	"status": "Status",
	"bidAction": "Kjøp",
	"residentialSaleFee": "Omkostninger",
	"residentialSaleFixed": "Fastpris",
	"residentialSaleFeeIncluded": "Angitt pris er inkl. omkostninger",
	"contact": "Kontakt | Kontakter",
	"editContact": "Endre kontakt",
	"createContact": "Opprett kontakt",
	"contactUpsell": "Jeg ønsker informasjon om lignende prosjekter",
	"leadPrivacy": "Jeg ønsker å bli kontaktet i samtykke med {0}",
	"contractPrivacy": "Jeg er innforstått med {0}",
	"salesmanVisibility": "Kontaktinformasjonen til kundebehandlerne på dette prosjektet vil bli vist på prosjektnettsiden",
	"areYouSure": "Er du sikker?",
	"somethingWentWrong": "Noe gikk galt",
	"completed": "Fullført",
	"complete": "Fullfør",
	"completing": "Fullfører",
	"youAreAboutToRemove": "Du holder på å slette",
	"removeRelationsTitle": "Du sletter også tilhørende:",
	"showAll": "Vis alle",
	"attachment": "Vedlegg | Vedlegg",
	"preview": "Forhåndsvis",
	"transferToUser": "Flytt tilkoblede data til:",
	"upcomingDeadlines": "Kommende frister",
	"noDeadlines": "Ingen kommende frister",
	"recentActivity": "Siste hendelser",
	"noActivity": "Ingen hendelser",
	"task": "Oppgave | Oppgaver",
	"noTasks": "Ingen oppgaver",
	"new": "Ny",
	"showCoordinates": "Vis koordinater",
	"readOurTerms": "Les gjennom avtalevilkårene",
	"iAcceptTerms": "Jeg har lest og godtar avtalevilkårene",
	"sendAndAccept": "Gå videre",
	"privacy": "Personvernerklæringen",
	"notfoundTitle": "Siden eksisterer ikke",
	"projectWillPublish": "Publiseres snart",
	"projectWillPublishAt": "Publiseres den {date}",
	"projectUnpublished": "Prosjektet er avsluttet",
	"pageMadeBy": "Siden er laget i",
	"processedBy": "Behandlet av",
	"markAsProcessed": "Merk som behandlet",
	"openhouseAtValue": "Visning på {value}",
	"noMoreResidentials": "Ingen flere boliger",
	"welcomeName": "Velkommen, {name}",
	"youHaveBeenAddedToCompany": "Du har blitt lagt til som bruker i {company}.",
	"activateAccount": "Aktiver konto",
	"pressButtonToActivateAccount": "Trykk på knappen for velge ditt passord og aktivere kontoen.",
	"orgNo": "Org. nr.",
	"howToGetLatLng": "For å få plassert prosjektet på kartet anbefaler vi deg å bruke {0} for å hente ut riktige koordinater. Adresse oppslag inneholder ofte ikke nye adresser og du er derfor nødt til å hente ut dette manuelt. I tillegg vil posisjonen bli mer nøyaktig.",
	"image": "Bilde | Bilder",
	"flatfinderBuilder": "Oppsett",
	"leaflet-v1": {
		"drawToolbar": {
			"actions": {
				"title": "Avbryt tegning",
				"text": "Avbryt"
			},
			"finish": {
				"title": "Fullfør tegning",
				"text": "Fullfør"
			},
			"undo": {
				"title": "Slett det sist tegnede punktet",
				"text": "Slett det siste punktet"
			},
			"buttons": {
				"polygon": "Tegn en markering"
			}
		},
		"drawHandlers": {
			"polygon": {
				"error": "Feil!",
				"tooltip": {
					"start": "Trykk for å tegne en markering.",
					"cont": "Trykk for å fortsette å tegne en markering.",
					"end": "Trykk på det første punktet for å lukke denne markeringen."
				}
			}
		},
		"editToolbar": {
			"actions": {
				"save": {
					"title": "Lagre endringer.",
					"text": "Lagre"
				},
				"cancel": {
					"title": "Avbryt redigering, forkast alle endringer.",
					"text": "Avbryt"
				},
				"clearAll": {
					"title": "Slett alle markeringer.",
					"text": "Slett alle"
				}
			},
			"buttons": {
				"edit": "Rediger markeringer.",
				"editDisabled": "Ingen markeringer å redigere.",
				"remove": "Slett markeringer.",
				"removeDisabled": "Ingen markeringer å slette."
			}
		},
		"editHandlers": {
			"edit": {
				"tooltip": {
					"text": "Dra punktene, eller marker for å redigere.",
					"subtext": "Trykk på avbryt for å forkaste endringer."
				}
			},
			"remove": {
				"tooltip": {
					"text": "Trykk på en markering for å slette."
				}
			}
		}
	},
	"theme": "Tema",
	"selectTheme": "Velg tema",
	"show": "Vis",
	"activateMarketingButton": "Kjøp ekstra synlighet",
	"couldNotLoadContent": "Kunne ikke laste innhold",
	"couldNotLoadContentMessage": "Prøv å last inn siden på nytt. Dersom problemet vedvarer vennligst ta kontakt med {0}.",
	"personalInformation": "Personlige opplysninger",
	"bidInformation": "Kjøpsinformasjon",
	"bidAuthentication": "Legimitasjon",
	"bidSigning": "Signering",
	"bidConfirmation": "Kjøp signert",
	"purchaseSigning": "Signering",
	"purchaseConfirmation": "Kjøpekontrakt signert",
	"socialSecurityNumber": "Fødselsnummer",
	"bidApplicant": "Kjøper",
	"bidHasCoApplicant": "Legg til medkjøper",
	"purchaseHasCoApplicant": "@:bidHasCoApplicant",
	"bidCoApplicant": "Medkjøper",
	"amount": "Beløp",
	"bidExpiresAt": "Akseptfrist",
	"bidTakeoverAt": "Ønsket overtakelse",
	"bidCondition": "Eventuelle forbehold",
	"passwordEnforcementMessage": "Passordet må bestå av minst 8 tegn, store/små bokstaver og minst 1 tall",
	"bank": "Bank",
	"financeContact": "Kontaktperson",
	"financingPlan": "Finansieringsplan",
	"signingAuthority": "Signeringsfullmektig",
	"bidContract": "Kjøpsbekreftelse",
	"purchaseContract": "Kjøpekontrakt",
	"shopContract": "Tilvalgskontrakt",
	"shop-partialContract": "Tilvalgskontrakt - Delsignering",
	"bidSignedMessage": "Ditt kjøp har blitt signert og sendt. Du vil om kort tid motta en bekreftelse på e-post. Så snart ditt kjøp har blitt behandlet vil du få svar på e-post.",
	"purchaseSignedMessage": "Din kjøpekontrakt har blitt signert og sendt. Du vil om kort tid motta en bekreftelse på e-post. Så snart din kjøpekontrakt har blitt behandlet vil du få svar på e-post.",
	"gotoProject": "Gå til prosjektet",
	"parent": "Forelder",
	"goBack": "Gå tilbake",
	"removeDemoContent": "Slett demo innhold",
	"unsavedChangesDialog": "Du har ulagrede endringer som går tapt hvis du fortsetter. Er du sikker på at du vil fortsette?",
	"confirmation": "Bekreftelse",
	"yourDocumentsAreAttached": "Vedlagt finner du alle dokumenter",
	"accept": "Godkjenn",
	"decline": "Avslå",
	"document": "Dokument | Dokumenter",
	"noDocuments": "Ingen dokumenter",
	"projectsite": "Prosjektnettside",
	"signer": "Undertegnende | Undertegnende",
	"youDoNotHaveSignAuthority": "Bare signeringsfullmektig kan godkjenne/avslå dette dokumentet",
	"update": "Oppdater",
	"fieldsToSync": "Data som skal overskrives",
	"selectAll": "Velg alle",
	"residentialTemplateSyncReferences": "Oppdater tilknyttede boliger",
	"askResidentialTemplateSyncReferences": "Ønsker du å oppdatere tilknyttede boliger?",
	"projectDomainSetup": "For at endringen blir synlig på nett, må du å sette opp en DNS peker for domenet ditt (se under). Husk at det kan ta litt tid før endringen blir synlig på nett. Ta kontakt på chat hvis du har spørsmål.",
	"lookingForAUserAdd": "Finner du ikke brukeren du leter etter?",
	"select": "Velg",
	"syncReferencesWarning": "Vær forsiktig når du velger hvilke data som skal overskrives. Alle valgte felt vil bli overskrevet med det nye innholdet.",
	"themeDoesNotSupportColor": "Det valgte temaet støtter ikke muligheten for tema farge.",
	"themeColor": "Tema farge",
	"youAreHere": "Du er her",
	"resetSelection": "Tilbakestill valg",
	"selection": "Valg",
	"thisIsADemo": "Dette er en demo",
	"userIsGuest": "Gjest / Har ikke tilgang til systemet",
	"guest": "Gjest",
	"inviteSent": "Invitasjon sendt",
	"demo": "Demo",
	"thisMessageHasBeenSentTo": "Denne beskjeden har blitt sendt til",
	"tourSeoAnalyticsTagManager": "SEO / Analytics / Tag Manager",
	"tourDeveloperTools": "Bruk av utviklerverktøy",
	"tourBusinessSite": "Slik endrer du bedriftsside ",
	"tourRecommendedImageFormat": "Anbefalte bildeformater",
	"tourHowToAddUser": "Slik legger du til en bruker",
	"tourHowToCreateProjects": "Slik oppretter du prosjekter",
	"tourSingleResidentialSale": "Enkelt bolig salg ",
	"tourHowToAddLeads": "Slik legger du til interessenter",
	"tourHowToCreateResidentials": "Slik oppretter du boliger",
	"addonPresentation": "Presentasjon",
	"addonBid": "Kjøp",
	"addonPurchase": "Kjøpekontrakt",
	"addonShop": "@:shop",
	"addonShopPurchase": "Tilvalgsbutikk - Signering",
	"tourAboutProjectDashboard": "Om prosjekt-dashbordet",
	"tourHowToEditResidentials": "Slik redigerer du boliger",
	"tourHowToDeleteResidentials": "Slik sletter du boliger",
	"tourTitleOnScreen": "Hjelpeguider (Interaktiv)",
	"tourTitleCourseVideos": "Opplæringsvideoer ",
	"tourTitleManuals": "Hjelpeguider (Manual)",
	"flatfinderIsEntry": "Hoved-enhetsvelger (vis denne først på prosjektsiden)",
	"flatfinderCreateResidentialWarning": "Når du setter opp en boligvelger, skal du koble til boliger. Det er derfor viktig at du først oppretter boligene før du setter opp boligvelgeren.",
	"flatfinderCreateResidentialWarningTitle": "Vi ser at du ikke har opprettet noen boliger",
	"flatfinderCreateResidentialWarningException": "Hvis du ikke skal sette opp boliger nå, kan du se bort ifra denne beskjeden og fortsette.",
	"continue": "Fortsett",
	"default": "Standard",
	"richTextUpdateLink": "Oppdater lenke",
	"richTextAddLink": "Legg til lenke",
	"multiplicatorMonthly": "mnd",
	"multiplicatorProject": "prosjekt",
	"contractStatusAccepted": "Godkjent",
	"contractStatusDeclined": "Avslått",
	"contractStatusDraft": "Utkast",
	"contractStatusSigned": "Et dokument venter på godkjenning",
	"contractStatusUnsigned": "Du har et dokument til signering",
	"bidErrorStatus": "Boligen er ikke til salg",
	"bidErrorAddon": "Modul for kjøp er ikke aktivert for dette prosjektet",
	"purchaseErrorStatus": "Boligen er ikke solgt",
	"purchaseErrorAddon": "Modul for kjøpekontrakt er ikke aktivert for dette prosjektet",
	"signed": "Signert",
	"gotoSigning": "Gå til signering",
	"sign": "Signer",
	"username": "Brukernavn",
	"accountUrl": "Link til konto",
	"password": "Passord",
	"otherBidAcceptedMessage": "Et annet kjøp ble godtatt",
	"bidConfirmationMessage": "Vi har mottatt ditt kjøp og du vil få svar fra oss så fort som mulig",
	"bidAcceptMessage": "Ditt kjøp har blitt godtatt",
	"bidDeclinedMessage": "Ditt kjøp har blitt avslått",
	"purchaseConfirmationMessage": "Vi har mottatt din kjøpekontrakt og du vil få svar fra oss så fort som mulig",
	"purchaseAcceptMessage": "Din kjøpekontrakt har blitt godtatt",
	"purchaseDeclinedMessage": "Din kjøpekontrakt har blitt avslått",
	"shopConfirmationMessage": "Vi har mottatt din signerte tilvalgsbestiling. Når motparten har signert denne vil du motta en bekreftelse. Vedlagt finner du en oversikt over din bestilling.",
	"shopAcceptMessage": "Din tilvalgskontrakt har blitt godtatt",
	"shopDeclinedMessage": "Din tilvalgskontrakt har blitt avslått",
	"contractExpiredMessage": "Frist har utløpt",
	"reason": "Begrunnelse",
	"pressHereToCancelBid": "Vil du annulere kjøpet?",
	"youAreUsingBidAddon": "Du bruker Kvass - Kjøpsmodul",
	"pressHere": "Trykk her",
	"void": "Annuller",
	"reasonWillBeSentToApplicants": "Denne beskjeden vil bli sendt videre til kunde",
	"howToBuildContract": "For at dokumentet skal bli utfyllt korrekt, trenger du å sette opp en utfyllbar PDF. Trykk på hjelpe-knappen nedenfor, for å se hvordan du gjør det.",
	"contractType": "Type",
	"createDocument": "Opprett dokument",
	"upload": "Last opp",
	"bidUploadInfo": "Etter at du har lastet opp den signerte kjøpsbekreftelsen vil boligen bli markert som solgt",
	"onSoldStatusDialog": "For å markere denne boligen som solgt, må du laste opp den signerte kjøpbekreftelsen.",
	"excludingVat": "ekskl. mva.",
	"includingVat": "inkl. mva.",
	"priceOnRequest": "Pris etter forespørsel",
	"priceFrom": "Pris fra",
	"pricePerMonth": "Pris per måned",
	"salePrice": "Utsalgspris",
	"priceMargin": "Påslag",
	"purchasePrice": "Innkjøpspris",
	"readMore": "Les mer",
	"upsaleRequestTemplate": "Jeg ønsker hjelp med \"{0}\"",
	"publishedFrom": "Publisert fra",
	"publishedTo": "Publisert til",
	"customerService": "Kundeservice",
	"residentialTemplateExplanation": "Ved å lage boligtyper slipper du å vedlikeholde det samme grunnlaget flere ganger. Du kan enkelt og raskt oppdatere alle boliger/ leiligheter som baserer seg på denne boligtypen, mens du samtidig beholder muligheten for å endre på boligene/ leilighetene individuelt på prosjektnivå.",
	"unitTemplateExplanation": "Ved å lage enhetstype slipper du å vedlikeholde det samme grunnlaget flere ganger. Du kan enkelt og raskt oppdatere alle enheter som baserer seg på denne typen, mens du samtidig beholder muligheten for å endre på enhetene individuelt på prosjektnivå.",
	"product": "Produkt | Produkter",
	"createProduct": "Opprett produkt",
	"allProducts": "Alle produkter",
	"category": "Kategori | Kategorier",
	"productCategorySample": "f.eks. Gulv, Parkett, VVS",
	"more": "Mer",
	"residentialStatusBidLock": "Du har aktivert \"kjøpsmodul\" og kan ikke endre status til eller fra \"solgt\" manuellt. Gå inn på bolig for å endre status",
	"noAnalyticsCollected": "Vi har ikke samlet nok data enda. Du vil se statistikk her så snart folk besøker nettsiden.",
	"sendEmail": "Send epost",
	"customThemeActiveMessage": "Du bruker en skreddersydd mal på dette prosjektet.",
	"validDnsSetupMessage": "Domenet er ikke satt opp på riktig måte - sjekk instruksene nedenfor og prøv igjen.",
	"siteSettings": "Side innstillinger",
	"showOpenhouseOnRequestIfNone": "Vis \"@:openhouseOnRequest\" dersom ingen visninger er planlagt",
	"showLeadUpsell": "Vis \"@:contactUpsell\" på interessent skjemaet",
	"showShopOnResidentialPage": "Vis tilvalgsbutikk på enhetssidene",
	"showShop": "Vis tilvalgsbutikk",
	"typeHere": "Skriv her",
	"shopCategoryExamples": "f.eks. Kjøkken, Bad",
	"shopSubcategoryExamples": "f.eks. Fliser, Innredning",
	"shopAddProduct": "Velg produkt",
	"shopAddCategory": "@:addCategory",
	"shopAddSubcategory": "Legg til underkategori",
	"lookingForAProductAdd": "Finner du ikke produktet du leter etter?",
	"hideSold": "Skjul solgte",
	"assetManagerTitleBrochureDirect": "Last ned prospekt",
	"assetManagerTitleBrochureLead": "Last ned prospekt",
	"assetManagerTitleBrochureRequest": "Få prospektet tilsendt",
	"assetManagerActionBrochureLead": "Last ned prospekt",
	"assetManagerActionBrochureRequest": "Send",
	"assetManagerFeedbackBrochureRequest": "Du vil få tilsendt prospektet innen kort tid.",
	"assetManagerCommentBrochureRequest": "Jeg ønsker å få tilsendt prospektet.",
	"brochureDownloadStrategyLabel": "Velg hvordan prospekt kan lastes ned",
	"brochureDownloadStrategyDirect": "Last ned direkte",
	"brochureDownloadStrategyLead": "Meld interesse først",
	"brochureDownloadStrategyRequest": "Send forespørsel / Send ut manuelt",
	"selected": "Valgt",
	"shopDefaultProduct": "Standard leveranse",
	"shopProductUpgrade": "Oppgraderingsmuligheter",
	"totalPrice": "Totalpris",
	"shopContactSellerForOtherInquiries": "Dersom du har andre ønsker ta kontakt med selger",
	"shopNotAvailableSize": "Åpne denne siden på en større skjerm for å få den beste opplevelsen av tilvalgsbutikken",
	"gotoItem": "Gå til {0}",
	"activate": "Aktiver",
	"whatDoYouWantToCopy": "Hva skal kopieres?",
	"helpTools": "Manualer / Guider",
	"needHelp": "Hjelp",
	"title": "Tittel",
	"nearbyarea": "Området",
	"shopSync": "Kopier",
	"shopSyncAll": "Hele tilvalgsbutikken",
	"badge-bid": "@:bid",
	"badge-comment": "@:comment",
	"badge-openhouse": "@:openhouse",
	"badge-shopcontract": "@:addonShop",
	"badge-lead": "@:lead",
	"badge-purchase": "@:addonPurchase",
	"badge-task": "Oppgave",
	"badge-customcontract": "@:contract",
	"prepareContract": "Klargjøring av kontrakt",
	"prepareShop": "Klargjøring av tilvalg",
	"preparePurchaseContract": "Klargjøring av kjøpekontrakt",
	"buyer": "Kjøper | Kjøpere",
	"sendToSigning": "Send til signering",
	"deadline": "Frist",
	"shopLink": "Lenke til tilvalgsbutikk (presentasjon)",
	"shopLinkWithSelection": "Lenke til tilvalgsbutikk (valg & signering)",
	"shopSigning": "Signering",
	"shopConfirmation": "Bekreftelse",
	"shopIntro": "Innledning",
	"shopSummary": "Oppsummering",
	"shopSignedMessage": "Din tilvalgskontrakt har blitt signert og sendt. Du vil om kort tid motta en bekreftelse på e-post. Så snart din tilvalgskontrakt har blitt behandlet vil du få svar på e-post.",
	"shopSigningNotReady": "Det er ikke åpnet for signering enda og du vil få beskjed så snart du kan signere",
	"startShopContract": "Start tilvalgsprosess",
	"shopReadyTitle": "Din tilvalgsbutikk er klar",
	"shopReadyMessage": "Du kan nå se og velge hvilke tilvalg du ønsker på din bolig. \nDet er ikke åpnet for signering enda men du kan allerede nå ta dine første valg. Så snart det åpnes for signering vil du få beskjed.",
	"shopWelcomeMessage": "Velkommen til din tilvalgsbutikk - her kan du se og velge hvilke tilvalg du ønsker på din bolig.\n\nSå snart du har tatt valgene dine kan du gå videre til oppsummeringen hvor du vil få mulighet til å signere din bestilling.",
	"shopUnsignedMessage": "Du kan nå velge og signere dine tilvalg",
	"gotoShop": "Gå til tilvalgsbutikken",
	"shopContractStatusReady": "Klar (sendt til kjøper)",
	"shopContractStatusDraft": "Kladd",
	"shopContractStatusUnsigned": "Venter på signatur",
	"shopContractStatusSigned": "@:signed",
	"shopContractStatusExpired": "Fristen har utløpt",
	"shopContractNotSold": "Denne boligen er ikke solgt enda. Så snart boligens status endres til solgt vil du kunne starte med signeringsprosessen.",
	"shopContractAttachment": "Vedlegg (signeres av kjøper)",
	"shopContractStartRequirements": "Før du kan starte tilvalgsprosessen må du legge inn kjøper. \nHusk å lagre endringene. ",
	"shopContractRememberSignableFrom": "Husk å sette \"@:signableFrom\" slik at kjøperen kan signere tilvalgsbestillingen.",
	"file": "Fil | Filer",
	"import": "Importer",
	"productImport": "Produkt import",
	"importedXItem": "Importerte {count} {item}",
	"importFailedResolveIssues": "Importen kunne ikke gjennomføres. Nedenfor finner du en oversikt over hva som har gått galt. Vennligst rett på dette og prøv på nytt.",
	"pressButtonBelowToGetStarted": "Trykk på knappen nedenfor for å komme i gang",
	"signingDeadlineIs": "Fristen for signering er",
	"shopLinkPresentation": "Presentasjon",
	"shopLinkSigning": "Valg / Signering (for kjøper)",
	"export": "Eksporter",
	"page": "Side | Sider",
	"tourGetStartedSingleShop": "Slik kommer du i gang med: Enkelt modul - Tilvalg ",
	"tourHowToAddPartDeadlinesShop": "Slik setter du delfrister på tilvalgssignering",
	"tourHowIfOrIfNotShopWork": "Slik fungerer hvis/ hvis ikke prosessen for tilvalg",
	"tourGetStartedShop": "Slik kommer du i gang med: Tilvalgsbutikk",
	"tourGetStartedShopContract": "Slik kommer du i gang med: Digital tilvalgssignering",
	"tourTaskManagement": "Slik fungerer oppgave styring i kundeoppfølgingssystemet",
	"tourGetStartedPurchaseContract": "Slik kommer du i gang med: Digital kjøpekontrakt",
	"tourGetStartedBidContract": "Slik kommer du i gang med: Digital kjøpsmodul",
	"tourGetStartedSingleBidContract": "Slik kommer du i gang med: Enkelt modul - Digital kjøpsmodul",
	"tourHowToEditProjectResidentials": "Slik redigerer du prosjektet/boliger",
	"tourHowToCreateFlatfinder": "Slik oppretter/redigerer du boligvelgere",
	"tourHowToCreateResidentialTemplate": "Slik oppretter/redigerer du boligtyper",
	"tourHowToBuildContract": "Slik setter du opp kjøpsbekreftelse ",
	"tourHowDashboardWorks": "Slik fungerer dashbordet",
	"tourHowToCreateProjectTeaser": "Slik oppretter du en teaser (Kommer til salgs)",
	"tourRoles": "Slik administrer du brukere ",
	"tourHowToPublish": "Slik publiserer du prosjektet under et domene",
	"tourTitleAdditionalServices": "Ønsker du bistand?",
	"tourHowToBuildPDF": "Slik setter du opp en utfyllbar PDF",
	"tourHowToHandleLead": "Slik håndterer du interessenter",
	"tourHowToHandleOpenhouse": "Slik håndterer du visninger",
	"howManyUnitsOnProject": "Hvor mange enheter skal selges totalt?",
	"attachmentIsEncryptedWithSSN": "Merk at vedlegget er kryptert - passordet er personnummeret ditt. Dokumentet du har mottatt er en kopi og originalet ligger hos selger.",
	"contractApprovalWarning": "Husk å kontrollere dokumentets innhold før godkjennelse.",
	"addon:project": "Prosjekt",
	"addon:project:presentation": "Prosjekt- / Boligside",
	"addon:project:bid": "Kjøpsmodul",
	"addon:project:purchase": "Kjøpekontrakt",
	"addon:project:shop": "Tilvalg - Presentasjon",
	"addon:project:shop-purchase": "Tilvalg - Salg",
	"addon:custom-contract": "Dokumentsignering",
	"addon:api": "API tilgang",
	"addon:flatfinder": "Boligvelger",
	"addon": "Modul | Moduler",
	"fixedPrice": "Fastpris",
	"areaPrice": "Pris pr. m²",
	"customerPrice": "Pris til kunde",
	"establishment": "Etablering",
	"ownershipType:leasehold": "Obligasjon",
	"ownershipType:freehold": "Eier (Selveier)",
	"ownershipType:other": "Annet",
	"ownershipType:partOwnership": "Andel",
	"ownershipType:collectiveOwnership": "Aksje ",
	"plotOwnershipType:leasehold": "Obligasjon",
	"plotOwnershipType:freehold": "Eier (Selveier)",
	"plotOwnershipType:other": "Annet",
	"plotOwnershipType:partOwnership": "Andel",
	"plotOwnershipType:collectiveOwnership": "Aksje ",
	"subpage": "Underside | Undersider",
	"orderItem": "Bestill {item}",
	"ShopPurchaseRequiresShop": "For å kunne ta i bruk \"Tilvalg - Salg\" trenger du også \"Tilvalg - Presentasjon\"",
	"projectUnitLimitReached": "Du kan opprette {0} enheter til på dette prosjektet. For å legge til flere enheter trenger du å oppgradere til en større lisens. Vennligst ta kontakt med support@kvass.no eller på chat for å oppgradere.",
	"slug": "Slug",
	"content": "Innhold",
	"setArea": "Sett areal",
	"rounding:none": "Ingen",
	"priceRounding": "Prisavrunding",
	"exampleShort": "f. eks.",
	"productOverrideMessage": "\"{value}\" er koblet opp mot en produktkatalog. Trykk på \"Rediger\" for å sette en egen verdi.",
	"productOverrideReset": "Koble \"{value}\" opp mot produktkatalog - fjern egen verdi.",
	"priceMissingArea": "Areal mangler",
	"total": "Totalt",
	"instagramProfile": "Instagram-profil",
	"numberOfPosts": "Antall innlegg",
	"followUsOn": "Følg oss på {value}",
	"customFieldsResidentialTemplatesLabel": "Presentasjon av boligtyper",
	"signableFrom": "Kan signeres fra",
	"setPartialDeadlines": "Sett delfrister",
	"removePartialDeadlines": "Fjern delfrister",
	"partialDeadline": "Delfrist | Delfrister",
	"madeBy": "En tjeneste fra",
	"gotoSummary": "Gå til oppsummering",
	"selectCategoriesToSign": "Velg kategorier som skal signeres",
	"shopBuyerStatusDraft": "Ikke åpnet for signering enda",
	"shopBuyerStatusUnsigned": "Klar for signering",
	"wholeShop": "Hele tilvalgsbutikken",
	"company": "Bedrift",
	"companyName": "Bedriftsnavn",
	"businessSite": "Bedriftsside",
	"private": "Privat",
	"footer": "Bunntekst",
	"creatingContracts": "Oppretter dokumenter for signering, vennligst vent et øyeblikk",
	"pressButtonBelowToGoToShop": "Trykk på knappen nedenfor for å gå til din tilvalgsbutikk",
	"youHaveNewShopInfo": "Du har mottatt oppdatert informasjon på din tilvalgsbutikk",
	"youHaveAnUpcomingDeadline": "Du har en frist som nærmer seg",
	"hiName": "Hei, {name}",
	"shopPresentationSidebarDescriptionTitle": "Sett opp din bolig",
	"shopPresentationSidebarDescriptionContent": "Her kan du se hvilke oppgraderinger som er tilgjengelig på denne boligen.",
	"signingLoadingMessage": "Vi ferdiggjør dine dokumenter - vennligst vent et øyeblikk (dette tar ca 15-30 sek.)",
	"tag": "Merkelapp | Merkelapper",
	"noTags": "Ingen merkelapper",
	"youHaveANewMessageFrom": "Du har fått en ny melding fra {from}",
	"nameSays": "{name} sier",
	"gotoDialog": "Gå til dialogen",
	"youCanAswerThisEmail": "Du kan svare på denne e-posten - ditt svar blir videresendt",
	"send": "Send",
	"note": "Notat",
	"thisMessageWasSentWith": "Denne meldingen har blitt sendt med",
	"addCategory": "Legg til kategori",
	"availableIf": "Tilgjengelig hvis",
	"availableIfNot": "Ikke tilgjengelig hvis",
	"xActiveConditions": "Ingen aktive regler | {count} aktiv regel | {count} aktive regler",
	"setConditions": "Sett regler",
	"displayName": "Visningsnavn",
	"useDisplayName": "Bruk et annet visningsnavn",
	"statisticsVisitors": "Besøkstall",
	"statisticsSalesResidentials": "Salgstall",
	"statisticsSalesShop": "Salgstall (Tilvalg)",
	"externalUserAlert": "Du prøver å legge til en bruker som er utenfor din organisasjon ({domain}). \nVedkommende vil få: \n{value} \n\nVennligst bekreft at du ønsker å legge til {email}.",
	"sendMessageToSeller": "Send melding til selger",
	"sendMessageToSellerButtonLabel": "Lurer du på noe? – Kontakt oss her",
	"sent": "Sendt",
	"member": "Medlem | Medlemmer",
	"editTask": "Rediger oppgave",
	"createTask": "Opprett oppgave",
	"undone": "Åpen",
	"noDescription": "Ingen beskrivelse",
	"noDeadline": "Ingen frist",
	"youHaveANewTask": "Du har fått en ny oppgave",
	"gotoTask": "Gå til oppgaven",
	"youHaveX": "",
	"gotoDashboard": "Gå til dashbordet",
	"youHaveXTaskDueIn": "Du har {count} oppgave som har frist innen {dueby} | Du har {count} oppgaver som har frist innen {dueby}",
	"projectAdditionalFieldsDescriptionFooter": "Legg til bunntekst. f.eks: Om oss/Adkomst",
	"projectAdditionalFieldsDescriptionResidentailTemplate": "Velg hvilke av dine boligtyper du ønsker å presentere på prosjektsiden.",
	"projectAdditionalFieldsDescriptionArea": "Legg inn tekster og bilder for å presentere området eller lignende.",
	"projectAdditionalFieldsDescriptionSubpage": "Lag undersider der du kan presentere bilder og tekst. Du kan linke til undersider ved å legge til lenker i tekstfeltene.",
	"clone": "Dupliser",
	"type": "Type | Typer",
	"filter": "Filtrer",
	"noShopDefaultProduct": "Ingen standard leveranse",
	"customer": "Kunde | Kunder",
	"purchaseManuallyConfirmed": "Opplastet kjøpekontrakt er signert",
	"cannotAddMoreUsers": "Du kan ikke legge til flere brukere. Vennligst kontakt support dersom du ønsker å legge til flere.",
	"videoPreviewMessage": "Legge til {type} lenke for forhåndvisning av video",
	"videoLink": "{type} lenke",
	"profile": "Profil | Profiler",
	"redirectToExternalSite": "Viderekoble til ekstern side",
	"residentialRedirectUrlExample": "eks. https://...",
	"optional": "valgfritt",
	"residentialRedirectUrlExplanation": "Bruk dette feltet dersom du ønsker å sende besøkere videre til et annet nettsted",
	"createdAt": "Opprettet den",
	"overview": "Oversikt",
	"mediaTypeComboMessage": "Eller trykk på knappen for å velge blant andre media typer",
	"mediaTypeDefaultMessage": "Trykk på knappen nedenfor for å laste opp andre filtyper",
	"userIsDeveloper": "Vis utviklerverktøy",
	"noFloorplansSetup": "Ingen plantegninger satt opp",
	"mark": "Marker",
	"createResidentialTemplateBeforeShop": "For å kunne opprette tilvalgsbutikken må du først opprette boligtypen",
	"noShopsetsSetup": "Ingen tilvalgspakker satt opp",
	"shopSet": "Tilvalgspakke | Tilvalgspakker",
	"productsThatArePartOfSet": "Produkter som er i tilvalgspakken",
	"manualSelectAndSkip": "Velg selv (Hopp over)",
	"event": "Hendelse | Hendelser",
	"url": "Url",
	"enabled": "Aktivert",
	"webhook": "Webhook | Webhooks",
	"createWebhook": "Opprett webhook",
	"setting": "Innstilling | Innstillinger",
	"webhookSecret": "Secret",
	"attempt": "Forsøk",
	"of": "av",
	"log": "Logg | Logg",
	"noWebhooks": "Ingen webhooks",
	"webhookIsSystem": "Systemintegrasjon",
	"noLogs": "Ingen logger",
	"token": "API nøkkel | API nøkler",
	"createToken": "Opprett API nøkkel",
	"noTokens": "Ingen API nøkler",
	"acl": "Tilgangskontroll",
	"tokenSecretMessage": "Vennligst kopier denne nøkkelen og lagre den på et trygt sted. Av sikkerhetsårsaker viser vi den bare en eneste gang.",
	"shopContractNoticeSellerApproachingSubject": "Kjøpers signeringsfrist utgår",
	"shopContractNoticeSellerOverdueSubject": "Kjøpers signeringsfrist har utgått",
	"shopContractNoticeSellerExpires": "Kjøper har ikke gjennomført signering av tilvalgskontrakt.",
	"unit": "Enhet | Enheter",
	"noUnits": "Ingen enheter",
	"noMoreUnits": "Ingen flere enheter",
	"createUnit": "Opprett enhet",
	"unitTemplate": "Enhetstype | Enhetstyper",
	"residentialSaleWithBroker": "Selg med megler ",
	"residentialSaleSelf": "Digital kontraktsinngåelse",
	"residentialSaleWithBrokerBidHelperText": "Her kan du legge til en valgfri lenke som du kan selge enheten med. Lenken vil være tilgjenglig \nunder \"kjøp\" knappen på enhetssiden. ",
	"bidContractMissingShowBidUrl": "Kjøpsbekreftelse er ikke lastet opp. \nLast opp kjøpsbekreftelse på prosjektet for å aktivere funksjoner for digitalt salg.",
	"residentialSaleBidHelperText": "Med denne lenken kan du selge enheten digitalt. \nLenken er tilgjengelig under “kjøp” knappen på enhetssiden, men lenken kan også kopieres herfra. \nKjøp som blir gjennomført via denne lenken vil dukke opp i selgerens dashbord. ",
	"residentialSaleBidActiveHelperText": "Kjøpsmodul er aktivert. Om du ønsker å ta i bruk en annen salgsmetode kan du aktivere en meglerintegrasjon. ",
	"pressButtonBelowToGoToShopNoticeSeller": "Trykk på knappen for mer informasjon om tilvalgsbutikken ",
	"copy": "Kopier",
	"copied": "Kopiert",
	"paywallContentFieldUsedByFollowingModules ": "Disse feltene er ikke relevant for aktiverte moduler",
	"paywallContentPressButtonToReadMore": "Trykk på knappen for å lese mer eller aktivere modulen | Trykk på en av knappene for å lese mer eller aktivere modulen",
	"deliveryLogClickLabel": "{resource} levert - åpnet",
	"deliveryLogOpenLabel": "{resource} levert - sett",
	"deliveryLogFailedLabel": "{resource} kunne ikke bli levert",
	"deliveryLogDeliveredLabel": "{resource} levert - ikke åpnet",
	"deliveryLogUnknownLabel": "{resource}  - venter på levering",
	"invite": "Invitasjon",
	"info": "Info",
	"oneOrMoreDeadlinesExpired": "En eller flere frister er utgått",
	"projectShopsOpenByBuyer": "Åpnet av kjøper",
	"contractStatusNotAccepted": "Venter på godkjenning",
	"noMoreShops": "Ingen flere tilvalgsbutikker",
	"projectShopsCreateResidentialsWarning": "Opprett enheter før du setter opp tilvalgsbutikker.",
	"projectShopsEditShop": "Ikke opprettet",
	"projectShopsCreateResidentials": "Opprett enhet",
	"projectShopsNotSold": "Ikke solgt",
	"projectSaleBrokerGuide1": "Under «salg» på enheter kan du legge til linker til kjøpsprosessen fra meglerløsninger",
	"projectSaleBrokerGuide2": "For eks. fra tryggbudgivning.",
	"projectSaleBrokerGuide3": "Om ønskelig kan også integrasjon mot meglerløsninger bestilles.",
	"residentialsFlatfinderGuideInfo": "Etter at du har lagt til enheter kan du om ønskelig opprette boligvelgere. Dette gjør du ved å gå til prosjektdashbordet og trykke «opprett» og deretter «boligvelger».",
	"shop": "Tilvalgsbutikk | Tilvalgsbutikker",
	"devtools": "Utviklerverktøy",
	"embed": "Embedding",
	"imageRecommendation": "Anbefalt bildeformat: {width} {symbol} {height}",
	"height": "høyde",
	"width": "bredde",
	"imageFlatfinderRecommendation": "Velg fritt - visningen tilpasser seg deretter",
	"consentRequiredTitle": "Nødvendige cookies",
	"consentRequiredDescription": "Nødvendige informasjonskapsler ivaretar grunnleggende funksjoner som sidenavigasjon og tilgang til sikre områder av nettstedet. Nettstedet kan ikke fungere optimalt uten disse informasjonskapslene.",
	"consentStatisticsTitle": "Statistikk",
	"consentStatisticsDescription": "Analytiske informasjonskapsler hjelper oss å forbedre nettstedet vårt ved å samle inn og rapportere statistikk informasjon om bruken. Informasjonen er anonymisert.",
	"consentMarketingTitle": "Markedsføring",
	"consentMarketingDescription": "Markedsførings-informasjonskapsler brukes for å vise innhold fra sosiale medier og annonser som er mer relevante og engasjerende.",
	"consentDialogTitle": "Ønsker du å godta cookies?",
	"consentDialogDescription": "Vi bruker informasjonskapsler (cookies) for å gi deg en best mulig brukeropplevelse. Informasjonskapslene brukes for å analysere trafikken vår, forbedre nettsidene, gi innhold og annonser et personlig preg.\n  For mer informasjon se vår <a href=\"/api/legal/privacy/tenant\">Personvernerklæring</a>.",
	"consentDialogLabelAccept": "Ja, aksepter alle",
	"consentDialogLabelAcceptAll": "Aksepter alle",
	"consentDialogLabelConfirm": "Bekreft",
	"consentDialogLabelConfigure": "Nei, endre innstillinger",
	"editConsents": "Endre personverninnstillinger",
	"noAttachments": "Ingen vedlegg",
	"tourVideoUpload": "Hvordan legge til video",
	"language": "Språk",
	"textOverwriteWarning": "Følgende tekst vises om {value} mangler:",
	"cookieVideoBlockMessage": "Vi trenger ditt samtykke for å spille av denne videoen",
	"sizes": "Størrelser",
	"productLink": "Produktlenke",
	"showDefaultSelection": "Vis standardvalg",
	"noUpgradesSelected": "Ingen oppgraderinger valgt",
	"setValue": "Sett {value} ...",
	"internalName": "Intern navn",
	"useInternalName": "Bruk eget intern navn",
	"shopWelcomeText": "Velkomsttekst",
	"scopedProduct": "Ikke fra katalog",
	"contactImport": "Kontakt import",
	"importTemplateDescription": "for importering",
	"socialMedia": "Sosiale medier",
	"downloadTemplate": "Last ned mal",
	"projectCreationVideoMessage": "Sjekk ut hvordan du kan bruke Kvass på {type} - se film over!",
	"singleResidential": "Enkeltbolig | Enkeltboliger",
	"myPage": "Min side",
	"contractStatusNotAcceptedBySeller": "Venter på godkjennelse av selger",
	"contractStatusAcceptedBySeller": "Godkjent av selger",
	"followSoMeTitle": "Følg oss på sosiale medier",
	"projectAdditionalFieldsSoMeDescriptionArea": "Legg til link til dine sosiale medier og vis det frem på nettsiden.",
	"projectAdditionalFieldsSeoDescriptionArea": "Overskriv SEO tilpasninger (tittel og beskrivelse) Ler mer her",
	"projectAdditionalFieldsFacebookAdsDescriptionArea": "Legg inn felter for oppsett av facebook annonsering",
	"SEO": "SEO",
	"facebookAds": "Facebook annonsering",
	"back": "Tilbake",
	"newFolder": "Ny mappe",
	"dragFolderOrDrop": "Dra mapper hit eller <b>trykk</b> for å laste opp",
	"managementOperationMaintenance": "FDV-dokumentasjon",
	"residentialAccount": "Min side",
	"clickToFilter": "Trykk for å filtrere",
	"imagesOnlyAsIllustration": "Bilder er ment som illustrasjon",
	"foundXResults": "Fant {0} resultater",
	"active": "Aktiv",
	"inactive": "Inaktiv",
	"contract": "Kontrakt | Kontrakter",
	"buyConfirmation": "Kjøp signert",
	"residentialAccountLogin": "Log inn på min side",
	"loginPressButton": "Klikk på knappen nedenfor for å logge inn",
	"loginVisitLink": "Eller lim inn denne lenken i nettleseren",
	"loginLinkExpires": "Dennen lenken utgår om {expires} minutter",
	"loginGetNewLink": "få tilsendt en ny lenke",
	"residentialAccountYourUnits": "Dine enheter",
	"residentialAccountLoginTooSeeUnits": "Logg inn for å se dine enheter",
	"loginEnterValue": "Fyll inn {value} og trykk på \"Fullfør\".",
	"loginSuccessMessage": "Du skal nå ha mottatt en mail med mer informasjon",
	"sortOn": "Sortér på",
	"highLow": "høy-lav",
	"lowHigh": "lav-høy",
	"syncUser": "Hent brukerdata",
	"lowHighLetter": "Å - A",
	"highLowLetter": "A - Å",
	"addFilter": "Legg til filter",
	"resetFilter": "Tilbakestill filter",
	"validTo": "Gyldig til",
	"registrationComplete": "Du har fullført registreringen.",
	"clickToLogin": "Trykk på knappen nedenfor for å logge inn.",
	"welcomeToKvass": "Velkommen til Kvass",
	"enterPasswordAndClickComplete": "Fyll inn passord og trykk på \"Fullfør\".",
	"tokenExpiredRequestNew": "Din invitasjon har utgått, vennligst be om ny. ",
	"author": "Forfatter",
	"post": "Innlegg | Innlegg",
	"createPost": "Opprett innlegg",
	"shopShort": "Tilvalg",
	"developer": "Utvikler",
	"access": "Tilgang | Tilganger",
	"supplier": "Leverandør | Leverandører",
	"technicalDescription": "Teknisk beskrivelse",
	"addTechnicalDescription": "Legg til teknisk beskrivelse",
	"switchTenant": "Bytt konto",
	"leadMessageSent": "Takk for din interesse. Du vil snart bli kontaktet av kundebehandler.",
	"externalUserAlertAdmin": "Tilgang til prosjektene og tilhørende data i løsningen ",
	"externalUserAlertNoRights": "Ingen tilgang til data i løsningen",
	"externalUserAlertRoles": "Rolle som: {roles} ",
	"externalUserAlertAccess": "Tilgang til: {access} ",
	"shopPresentationPreviewAlert": "Tilvalgsbutikken mangler data. \nForhåndvisning vil være tilgjengelig etter tilvalgsbutikken er satt opp.",
	"addon:project:complaint": "Reklamasjon",
	"archived": "Arkivert",
	"showArchived": "Vis arkiverte",
	"archive": "Arkiver",
	"integrationCompliance": "Det bekreftes herved at Kvass instrueres til å overføre personopplysninger gjennom vår integrering og at jeg/vi er selvstendig behandlingsansvarlig for overføringen og den videre behandling av personopplysningene.",
	"documentSigned": "Dokument signert",
	"documentSignedConfirmationMessage": "Dokumentet har blitt signert og sendt. Du vil om kort tid motta en bekreftelse på e-post. Så snart ditt dokument har blitt behandlet vil du få svar på e-post.",
	"integrationNotificationEmailBody": "Vi vil informere om at en ny {type} har lagt til på konto: {account}.",
	"integrationNotificationEmailBodyReminder": "Husk også å sørge for at nødvendige databehandleravtaler med alle underleverandører er på plass.",
	"integrationNotificationEmailSubject": "En ny {type} med navn \"{name}\" har blitt lagt til",
	"noContracts": "Ingen kontrakter",
	"awaitingSignature": "Venter på signatur",
	"residentialAddressControl": "Kontroller adresse",
	"residentialAccountNotSold": "Denne enheten er ikke solgt enda. Så snart enhetens status endres til solgt vil innstillinger være tilgjengelig.",
	"createContract": "Opprett kontrakt",
	"lastUpdated": "Sist oppdatert",
	"complaint": "Reklamasjon | Reklamasjoner",
	"lookingForAContactAdd": "Finner du ikke kontakten du leter etter?",
	"orderConfirmationCondition": "Alle produkter som aktiveres har en bindingstid på {lockInPeriod}. Produkter fornyes automatisk løpende med {noticePeriod} om den ikke sies opp av en av partene skriftlig før inneværende periode utløper.",
	"orderConfirmationConditionShort": "{lockInPeriod} bindingstid / fornyes automatisk etter {noticePeriod} ",
	"orderConfirmation": "Ordrebekreftelse",
	"unitLimitMessage": "Du kan opprette inntil {limit} enheter.",
	"taskStatusUndone": "Åpen",
	"taskStatusDone": "Fullført",
	"taskStatusRejected": "Avslått",
	"taskStatusRequest": "Forespørsel",
	"subjectArea": "Fagområdet",
	"showCompleted": "Vis fullførte",
	"complaintRequest": "Henvendelse | Henvendelser",
	"complaintTermsOfRequest": "Hva gjelder det?",
	"complaintAddSelection": "Legg til markering",
	"complaintRegister": "Send inn reklamasjon",
	"companyOrder": "{company} har bestilt tjenesten",
	"activatedBy": "Tjenesten ble aktivert av {user}",
	"orderConfirmationAttached": "Vedlagt finner du en ordrebekreftelse.",
	"install": "Installer",
	"uninstall": "Avinstaller",
	"installed": "Installert",
	"residentialAccountPublishAlert": "Ved publisering vil eiere motta varsling om innlogging til Min side.",
	"reset": "Tilbakestill",
	"showLess": "Vis mindre",
	"residentialAccountAccess": "Du har fått tilgang til Min side",
	"taskChangeStatus": "{type} '{title}' er {status}",
	"taskChangeStatusSubject": "{type} er {status}",
	"residentialAccountSettingsShowShop": "Tilgjengeliggjør tilvalgsbutikken på Min side",
	"residentialAccountSettingsShowShopSublabel": "Vis oppsummering, frister og annet relevant innhold",
	"residentialAccountSettingsShowShopDocuments": "Vis dokumentasjon på bestilte tilvalg på Min side",
	"residentialAccountSettingsShowShopDocumentsSublabel": "Dokumentasjon autogenereres og vises under FDV innhold",
	"residentialAccountSettingsShowResidentialDocuments": "Vis enhetens dokumenter på Min side",
	"residentialAccountSettingsShowResidentialDocumentsSublabel": "Kontrakter, plantegninger, vedlegg og prospekter",
	"redirectUrl": "Adresse for videresending",
	"contactSeller": "Kontakt selger",
	"importFromCatalog": "Hent fra katalog",
	"reject": "Avslå",
	"rejected": "Avvist",
	"addon:project:flatfinder": "Boligvelger",
	"blockComplaints": "Blokker mottak av reklamasjoner for denne enheten",
	"unblockComplaints": "Opphev blokkering av reklamasjoner for denne enheten",
	"complaintsBlocked": "Mottak av reklamasjoner for denne enheten er blokkert.",
	"taskStatusChangeToUndone": "under behandling",
	"taskStatusChangeToDone": "behandlet",
	"taskStatusChangeToRejected": "avslått",
	"attachmentsSigningUploadWarning": "Vedlegg må gjennomleses før signering kan gjennomføres. \nOBS: Vedlegg vil ikke være med i hovedkontrakt som signeres.",
	"addonIsIncludedIn": "{addon} er inkludert i {in}",
	"tourHowToEmbedFlatfinder": "Embed boligvelger på eksterne sider",
	"helpers:roleManager": "Kan redigere prosjekt og enheter.",
	"helpers:roleSigningAuthority": "Kan godkjenne eller avslå kjøp.",
	"helpers:roleSalesman": "Får lesetilgang og vises som kontaktperson.",
	"helpers:roleShopManager": "Kan redigere tilvalgsbutikker.",
	"helpers:roleShopSigningAuthority": "Kan godkjenne eller avslå tilvalgskontrakter.",
	"helpers:roleShopSalesman": "Får lesetilgang og vises som kontaktperson.",
	"salesmanShopVisibility": "Kontaktinformasjonen til kundebehandlerne for tilvalg vil bli vist i signeringsprosessen",
	"productBundleContains": "Inkluderer følgende produkter",
	"productBundle": "Produktpakke",
	"rent": "Utleie",
	"saleType": "Salgstype",
	"actionLabel:export": "Eksporter",
	"actionLabel:integration": "Integrasjoner",
	"action": "Handling | Handlinger",
	"helpers:productBundle": "En produktpakke er en sammensetning av flere enkeltprodukter. Du kan velge hvilke produkter som er inkludert, og sluttkunde kan velge produktpakken og se informasjon om hvert enkeltprodukt.",
	"office": "Kontor",
	"integrationDpaRecommendation": "Forslag til databehandleravtale",
	"ordinaryProductPrice": "Ordinær produktpris",
	"helpers:shopPricingMethodTitle:add": "Standard",
	"helpers:shopPricingMethodDescription:add": "Her er standardproduktet alltid inkludert i prisen. Pris på oppgraderinger tar utgangspunkt i ordinær produktpris.",
	"helpers:shopPricingMethodTitle:diff": "Differanse",
	"helpers:shopPricingMethodDescription:diff": "Her beregnes mellomlegget mellom standardproduktet og oppgraderingen.",
	"selectMethod": "Velg metode",
	"priceCalculation": "Prisberegning",
	"unitNumber": "Enhetsnummer",
	"productUpdateAffectedProducts": "Endringene påvirker {products} produktvalg i {shops} tilvalgsbutikker",
	"upcomingLabel": "Kommer snart",
	"hide": "Skjul",
	"residentialAdditionalFieldsLabel": "Andre felter som kan legges til:",
	"link": "Lenke | Lenker",
	"loggingIn": "Logger inn",
	"setDueDates": "Sett frister",
	"complaintDescription": "Beskriv reklamasjonen",
	"helpers:managementOperationMaintenance": "Last opp FDV dokumenter her. Samtlige dokumenter vil vises på Min side. \nI tillegg har du mulighet til å vise dokumentasjon på tilvalg, plantegninger, kontrakter og vedlegg knyttet til enheten. (kan aktiveres under instillinger)",
	"helpers:roleComplaintManager": "Mottar alle innkommende reklamasjonssaker",
	"residentialAccountLoginCondition": "Email adressen må være registert i systemet",
	"noComplaints": "Ingen reklamasjoner",
	"complaintDisclaimerConsent": "Jeg bekrefter at utredning av uberettigede reklamasjonshenvendelser vil kunne bli belastet kunden med våre faktiske kostnader.",
	"complaintDisclaimerEdit": "Rediger ansvarsfraskrivelse",
	"fdvControlLabel": "FDV er lastet opp og kontrollert",
	"fdvControlSublabel": "Huk av så snart FDV er komplett og kontrollert",
	"shopEditDueAtUnitNotSold": "Boligene er ikke solgt enda. Så snart boligens status endres til solgt vil du kunne sette frister.",
	"confirmed": "Bekreftet",
	"control": "Kontroll",
	"noMembers": "Ingen medlemmer",
	"complaintRoleMissing": "Rolle for håndtering av reklamasjoner mangler på prosjektet. ",
	"folder": "Mappe | Mapper",
	"helpers:complaintsList": "Via Min side kan kjøper sende inn reklamasjoner og samtlige reklamasjonssaker for denne enheten vises her.",
	"completeTask": "Fullfør oppave",
	"changeStatus": "Endre status",
	"confirmRequest": "Bekreft forespørsel",
	"rejectRequest": "Avslå forespørsel",
	"setTaskToUndone": "Sett tilbake til \"Åpen\"",
	"cannotEditMultipleResidentialFieldsSameSaleType": "Enhetene har forskjellig salgstype og kan derfor ikke redigeres sammen",
	"noContacts": "Ingen kontakter",
	"filterEmptyState": "Endre filtrene og prøv igjen",
	"invoice": "Faktura",
	"receiver": "Mottaker | Mottakere",
	"addon:project:presentation:sublabel": "Sett raskt opp en nettside med boligvelger.",
	"addon:project:bid:sublabel": "Send ut og signer kjøpekontrakten digitalt.",
	"addon:project:purchase:sublabel": "Selg enkelt & digitalt via prosjekt-/boligsiden.",
	"addon:project:shop:sublabel": "Presenter tilvalg digitalt via Prosjekt-/boligsiden eller med en egen lenke.",
	"addon:project:shop-purchase:sublabel": "Selg tilvalg digitalt via Prosjekt-/boligsiden, Min Side eller med en egen lenke.",
	"addon:project:residential-account:sublabel": "Gi dine kunder tilgang til Min Side",
	"addon:project:residential-account": "Min Side (inkl. ved aktiv prosjektlisens)",
	"billing": "Fakturamottaker",
	"featuredResidentialPropertyHelper": "Fremhev denne nøkkelverdien på prosjektsiden",
	"viewProject": "Se prosjekt",
	"unitSelectorIsEntry": "Hoved-enhetsvelger",
	"autocompleteAddressAlert": "Har du ingen adresse enda? Flytt markøren på kartet for å sette posisjon.",
	"externalFileDisclaimer": "Innholdet i filene er ikke kontrollert. Du er selv ansvarlig for nedlastning av eksterne filer.",
	"getMarkerPosition": "Hent posisjon fra kart",
	"createResource": "Opprett {resource}",
	"taskCommentsReceiverInfo": "Meldinger blir sendt til medlemmer og den som har opprettet oppgaven",
	"createdBy": "Opprettet av",
	"openhouseEmptyState": "Kom i gang ved å opprette en visning",
	"documentsEmptyState": "Det er ingen dokumenter tilknyttet denne enheten",
	"startShops": "Start flere tilvalgsbutikker",
	"noUnitTemplates": "Ingen enhetstyper",
	"unitTemplatesEmptyState": "Kom i gang ved å opprette en enhetstype",
	"noApplicantShopContractTooltip": "En eller flere av tilvalgsbutikkene er ikke klare for å bli startet enda og vil bli ignorert.",
	"noUsers": "Ingen brukere",
	"usersEmptyState": "Kom i gang ved å opprette en bruker",
	"cannotCreateMoreX": "Kan ikke opprette flere {x}",
	"cannotDelete": "Kan ikke slette",
	"noX": "Ingen {x}",
	"thisFieldIsFetchedFromX": "Dette feltet hentes fra {x}",
	"addSecondaryColor": "Legg til @:secondaryColor",
	"primaryColor": "Primærfarge",
	"secondaryColor": "Sekundærfarge",
	"unitMustBeSoldToSetOwner": "Enheten må være solgt / utleid før du kan velge eiere på enheten",
	"owner": "Eier | Eiere",
	"tableFooterTip": "Tips: Trykk og hold {0} for å markere flere samtidig.",
	"sendMessage": "Send melding",
	"mediaDescriptionPlaceholder": "Legg inn bildetekst",
	"taskTypeDefault": "@:task",
	"taskTypeComplaint": "@:complaint",
	"downloadX": "Last ned {x}",
	"noUnsavedChanges": "Ingen ulagrede endringer",
	"installMore": "Installer flere",
	"propertyType:four-person-home": "Firemannsbolig",
	"addCompanyInfo": "Legg til bedriftsinfo",
	"shopTotalLabel": "Totalpris for tilvalg",
	"addDesiredAmount": "Legg inn ønsket beløp under",
	"creationDate": "Dato opprettet",
	"shop-partialConfirmationMessage": "Vi har mottatt din signerte tilvalgsbestiling. Når motparten har signert denne vil du motta en bekreftelse. Vedlagt finner du en oversikt over din bestilling.",
	"shop-partialAcceptMessage": "Din tilvalgskontrakt har blitt godtatt",
	"shop-partialDeclinedMessage": "Din tilvalgskontrakt har blitt avslått",
	"customConfirmationMessage": "Vi har mottatt din kontrakt og du vil få svar fra oss så fort som mulig",
	"customAcceptMessage": "Din kontrakt har blitt godtatt",
	"customDeclinedMessage": "Din kontrakt har blitt avslått",
	"noteVisibilityMessage": "Kun synlig internt"
}